/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import gcdHcf from "../../../../images/Number Theory/gcd-or-hcf.webp";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    pre: "pre",
    code: "code",
    blockquote: "blockquote",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "gcd-or-hcf-of-two-numbers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#gcd-or-hcf-of-two-numbers",
    "aria-label": "gcd or hcf of two numbers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "GCD or HCF of two numbers"), "\n", React.createElement(_components.p, null, "and program in Rust to calculate it using Euclidean algorithm."), "\n", React.createElement(_components.h2, {
    id: "what-is-gcd-or-hcf",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-gcd-or-hcf",
    "aria-label": "what is gcd or hcf permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is GCD or HCF"), "\n", React.createElement(_components.p, null, "Greatest Common Divisor ( GCD ) or Highest Common Factor ( HCF ) of two natural numbers is the largest natural number that divides both numbers."), "\n", React.createElement(_components.p, null, "We can also say it is the largest natural number that is factor of both numbers."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "For Example :"), " GCD of 100 and 75 is 25"), "\n", "\n", React.createElement("div", {
    style: {
      textAlign: 'center'
    }
  }, React.createElement("img", {
    src: gcdHcf,
    width: "100%",
    alt: "GCD of 150 and 210 is 30"
  })), "\n", React.createElement(_components.h2, {
    id: "naive-approach",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#naive-approach",
    "aria-label": "naive approach permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Naive Approach"), "\n", React.createElement(_components.p, null, "Let us suppose we have to find gcd or hcf of 2 numbers, a and b."), "\n", React.createElement(_components.p, null, "Naive or brute force approach is to traverse all the numbers from 1 to min(a, b), and check if the number divides both numbers. Function using this approach is"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn gcd(a:usize, b:usize) -> usize{\n    // Initialize ans, or answer and limit\n    let mut ans:usize = 1;\n    let limit = min(a,b);\n\n    // Loop from 2 to limit, both inclusive\n    for i in 2..(limit+1) {\n        // Check if both a and b are divisible\n        if a%i == 0 && b%i == 0 {\n            ans = i;\n        }\n    }\n    return ans;\n}\n")), "\n", React.createElement(_components.p, null, "Program With driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::cmp::min;\nuse std::io::stdin;\n\n// Read Input\n\nfn take_vector() -> Vec<usize> {\n    let mut input = String::new();\n    stdin().read_line(&mut input).unwrap();\n    let arr: Vec<usize> = input.trim().\n        split_whitespace().map(|x| x.parse().unwrap()).collect();\n    return arr;\n}\n\n// Magic Starts here\n\nfn gcd(a:usize, b:usize) -> usize{\n    // Initialize ans, or answer and limit\n    let mut ans:usize = 1;\n    let limit = min(a,b);\n\n    // Loop from 2 to limit, both inclusive\n    for i in 2..(limit+1) {\n        // Check if both a and b are divisible\n        if a%i == 0 && b%i == 0 {\n            ans = i;\n        }\n    }\n    return ans;\n}\n\n// Driver Code\n\npub fn main() {\n    let numbers = take_vector();\n    println!(\"{}\", gcd(numbers[0], numbers[1]));\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "210 150"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "30"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( min (a, b) )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.h2, {
    id: "efficient-euclidean-algorithm",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#efficient-euclidean-algorithm",
    "aria-label": "efficient euclidean algorithm permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Efficient Euclidean algorithm"), "\n", React.createElement(_components.p, null, "We can find the gcd of 2 numbers using Euclidean algorithm in ", React.createElement(_components.strong, null, "logarithmic time complexity")), "\n", React.createElement(_components.p, null, "Refer ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Euclidean_algorithm"
  }, "Wikipedia")), "\n", React.createElement(_components.p, null, "In simple words, let us suppose that we have to find gcd of 2 distinct numbers, a and b, such that a > b."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If a%b == 0, then obviously b is the gcd."), "\n", React.createElement(_components.li, null, "If a%b != 0, then gcd must divide both b and a%b ( from Euclidean algorithm or even general observation ). So, we can now find gcd of b and a%b, which will be the answer."), "\n"), "\n", React.createElement(_components.p, null, "Also, clearly, b > a%b."), "\n", React.createElement(_components.p, null, "Function using this approach is"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn gcd(mut a:usize, mut b:usize) -> usize{\n    // If equal, return any of them\n    if a==b {\n        return a;\n    }\n\n    // Swap a with b, if b is greater than a\n    if b > a {\n        std::mem::swap(&mut a, &mut b);\n    }\n\n    while b>0 {\n        // This is the trickiest part\n        // We swap a with b, and b with a%b, till b becomes 0\n        let temp = a;\n        a = b;\n        b = temp%b;\n    }\n\n    // Now, a%b = 0, hence return it\n    return a;\n}\n")), "\n", React.createElement(_components.p, null, "Use the same driver code."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "210 150"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "30"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( log(min (a, b)) )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "Greatest Common Divisor ( GCD ) or Highest Common Factor ( HCF ) of two natural numbers is the largest natural number that divides both numbers. In this article, we made a program to compute GCD or HCF in ", React.createElement(_components.strong, null, "Logarithmic Time Complexity"), " using Euclidean algorithm."), "\n", React.createElement(_components.p, null, "Here is the optimized function for easy access"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn gcd(mut a:usize, mut b:usize) -> usize{\n    if a==b { return a; }\n    if b > a { std::mem::swap(&mut a, &mut b); }\n    while b>0 {\n        let temp = a;\n        a = b;\n        b = temp%b;\n    }\n    return a;\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Calculate GCD or Greatest Common Divisor - Rust Programming",
    description: "Greatest Common Divisor or Highest Common Factor of two natural numbers is the largest natural number that divides both numbers. We will make function to find GCD or HCF of two numbers and optimize it to logarithmic time complexity using Euclidean algorithm.",
    img: "https://rustp.org/Static_Images_DND/Social/GCD_Social.png"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
